const deleteMsgHistory = async (sessionId) => {
  try {
    const response = await fetch(
      `https://stg-ai-api.shutswealth.com/api/MessageHistory/${sessionId}`,
      {
        method: "DELETE",
      }
    );
    if (!response.ok) {
      console.error("Failed to delete session ID");
      return false;
    }
    return true;
  } catch (error) {
    console.error("Failed to delete session ID:", error);
    return false;
  }
};

export default deleteMsgHistory;
