// src/utils/debounce.js
export const debounce = (func, wait) => {
  let timeout;

  const executedFunction = function(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };

  executedFunction.cancel = () => clearTimeout(timeout);

  return executedFunction;
};
