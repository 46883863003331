const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const retry = async (fn, maxAttempts = 3, delayMs = 500) => {
  const execute = async (attempt) => {
    try {
      return await fn();
    } catch (err) {
      if (attempt <= maxAttempts) {
        const nextAttempt = attempt + 1;
        console.error(
          `Attempt #${nextAttempt}: Retrying after 500 ms due to:`,
          err
        );
        await delay(delayMs);
        return execute(nextAttempt);
      } else {
        throw err;
      }
    }
  };
  return execute(1);
};

export default retry;
