import { atomWithStorage } from "jotai/utils";

// Helper function to create atoms with dynamic keys based on `botId`
const atomWithSessionKey = (key, botId, initialValue) => {
  const storageKey = `${botId}-${key}`;
  return atomWithStorage(storageKey, initialValue);
};

// Function to create atoms based on the current `botId`
export const createSessionAtoms = (botId) => ({
  messagesAtom: atomWithSessionKey("messages", botId, []),
  showClearModalAtom: atomWithSessionKey("showClearModal", botId, false),
  isSendingAtom: atomWithSessionKey("isSending", botId, false),
  isChatLoadingAtom: atomWithSessionKey("isChatLoading", botId, false),
  chatErrorAtom: atomWithSessionKey("chatError", botId, false),
  inputValueAtom: atomWithSessionKey("inputValue", botId, ""),
  consentFormDataAtom: atomWithSessionKey("consentFormData", botId, {
    isFormSubmitted: false,
  }),
  sessionIdAtom: atomWithSessionKey("sessionId", botId, ""),
});
