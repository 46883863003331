const fakeMessageData = [
  {
    content: "Suspendisse vestibulum, lorem ac ornare pretium.",
    role: 1,
    functionName: null,
  },
  {
    content: "ultricies nisi dapibus mauris pellentesque.",
    role: 2,
    functionName: null,
  },
];

export default fakeMessageData;
