const fetchSessionId = async (formData, chatBotSid) => {
  const formBody = {
    ...formData,
    isFormSubmitted: formData?.isFormSubmitted ? "true" : "false",
  };

  const response = await fetch(
    "https://stg-ai-api.neevai.io/api/SessionManagement/v2",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chatBotSid: chatBotSid,
        formData: formBody,
      }),
    }
  );

  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error("Failed to fetch session ID");
  }
};

export default fetchSessionId;
