import React, { useEffect } from "react";
import { debounce } from "../../utils/debounce";
import "./index.scss";
import { ReactComponent as Logo } from "../../images/logo.svg";
import { ReactComponent as Arrow } from "../../images/upArrow.svg";

const TextInput = ({
  sendMessage,
  setMessages,
  isSending,
  setIsSending,
  inputValue,
  setInputValue,
  disabled,
}) => {
  useEffect(() => {
    const debouncedSend = debounce((message) => {
      sendMessage(message);
    }, 300);

    return () => {
      debouncedSend.cancel();
    };
  }, [sendMessage]);

  const handleSend = () => {
    if (inputValue.trim() !== "") {
      sendMessage(inputValue);
      setMessages((prevMessages) => [
        ...prevMessages,
        { content: inputValue, role: 1, functionName: null },
      ]);
      setInputValue("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !isSending) {
      setIsSending(true);
      handleSend();
      event.preventDefault();
    }
  };

  return (
    <>
      <div
        className={`shuts-chat-input-wrapper ${
          isSending && "shuts-chat-input-wrapper--sending"
        }`}
      >
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type a message..."
          className="shuts-chat-text-input"
          disabled={disabled}
        />
        <button
          className="shuts-chat-btn"
          onClick={handleSend}
          disabled={!disabled ? isSending : disabled}
        >
          <Arrow />
        </button>
      </div>
      <p className="shuts-chat-signature">
        Powered by
        <Logo />
      </p>
    </>
  );
};

export default TextInput;
