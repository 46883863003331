import React, { useMemo } from "react";
import { useAtom } from "jotai";
import { createSessionAtoms } from "../../atoms";
import { RESET } from "jotai/utils";
import MessageList from "../MessageList";
import TextInput from "../TextInput";
import fetchSessionId from "../../api/fetchSessionId";
import deleteMsgHistory from "../../api/deleteMsgHistory";
import ConsentForm from "../ConsentForm";
import retry from "../../utils/retry";
import "./index.scss";

const Chat = () => {
  // Extract chatId directly from the query parameters
  const sanitizeInput = (value) => value.replace(/[<>\\/\\]/g, "");
  const params = new URLSearchParams(window.location.search);
  const pathId = sanitizeInput(window.location.pathname || "");
  const paramId = sanitizeInput(params?.get("id") || "");
  const chatId = pathId || paramId || "no-id";

  const useFakeDataParam = params?.get("useFakeData") || "";
  const showFormParam = params?.get("showForm") || "";
  const useFakeData = sanitizeInput(useFakeDataParam) === "true";
  const showForm = sanitizeInput(showFormParam) === "true";

  // Use useMemo to memoize the dynamic atoms
  const {
    messagesAtom,
    showClearModalAtom,
    isSendingAtom,
    isChatLoadingAtom,
    chatErrorAtom,
    inputValueAtom,
    consentFormDataAtom,
    sessionIdAtom,
  } = useMemo(() => createSessionAtoms(chatId), [chatId]);

  // Use hooks unconditionally
  const [sessionId, setSessionId] = useAtom(sessionIdAtom);
  const [messages, setMessages] = useAtom(messagesAtom);
  const [showClearModal, setShowClearModal] = useAtom(showClearModalAtom);
  const [isSending, setIsSending] = useAtom(isSendingAtom);
  const [isChatLoading, setIsChatLoading] = useAtom(isChatLoadingAtom);
  const [input, setInput] = useAtom(inputValueAtom);
  const [chatError, setChatError] = useAtom(chatErrorAtom);
  const [formData, setFormData] = useAtom(consentFormDataAtom);

  const sendMessage = async (messageContent) => {
    setIsChatLoading(true);
    setChatError(false);
    let id = sessionId;

    if (!sessionId || sessionId?.length === 0) {
      try {
        id = await fetchSessionId(formData, chatId);
        if (id && id !== sessionId) {
          setSessionId(id);
        }
      } catch (error) {
        console.error("Error fetching session ID:", error);
        return;
      }
    }

    if (formData.consent === "false") {
      setIsChatLoading(false);
      setFormData(RESET);
      return;
    }

    const apiUrl = "https://stg-ai-api.neevai.io";
    const url = `${apiUrl}/api/CommunicationChat/messages/${id}`;
    const headers = {
      accept: "*/*",
      "Content-Type": "application/json",
    };
    const requestBody = {
      systemPrompt: "",
      messages: [
        {
          content: messageContent,
          role: 1,
          functionName: null,
        },
      ],
      isManageMessageHistory: true,
      useStreaming: false,
    };

    try {
      const responseData = await retry(async () => {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error("Failed to send message");
        }

        return await response.json();
      });

      setMessages(responseData.messages);
      setIsChatLoading(false);
      setIsSending(false);
      setInput("");
      return responseData;
    } catch (error) {
      console.error("Error sending message:", error);
      setInput(messageContent);
      setChatError(true);
      setIsChatLoading(false);
      setIsSending(false);
      setMessages((prevMessages) => prevMessages.slice(0, -1));
    }
  };

  const handleClearClick = async () => {
    setShowClearModal(false);
    setMessages(RESET);
    deleteMsgHistory(sessionId);
    setIsChatLoading(false);
    setIsSending(false);
    setInput("");
    setChatError(false);
  };

  // Ensure `chatId` is available, else show an error
  if (chatId === "no-id") {
    return <div className="error">Error: Bot ID is required to proceed.</div>;
  }

  return (
    <div className="shuts-chat-box">
      <ConsentForm
        chatId={chatId}
        formData={formData}
        setFormData={setFormData}
        showForm={showForm}
        disabled={useFakeData}
      />
      <>
        <button
          className="shuts-clear-button"
          onClick={() => setShowClearModal(true)}
          disabled={useFakeData}
        >
          Clear chat
        </button>

        {showClearModal && (
          <div className="shuts-clear-modal">
            <p>Are you sure you want to clear the chat?</p>
            <div className="shuts-clear-modal__buttons">
              <button onClick={handleClearClick}>Yes</button>
              <button onClick={() => setShowClearModal(false)}>No</button>
            </div>
          </div>
        )}
        <MessageList
          isChatLoading={isChatLoading}
          messages={messages}
          chatError={chatError}
          useFakeData={useFakeData}
        />
        <TextInput
          sendMessage={sendMessage}
          setMessages={setMessages}
          isSending={isSending}
          setIsSending={setIsSending}
          inputValue={input}
          setInputValue={setInput}
          disabled={useFakeData}
        />
      </>
    </div>
  );
};

export default Chat;
