import React, { useState, useEffect, useRef } from "react";
import { getBotForm } from "../../api/grabBotData";
import "./index.scss";

const defaultFormFields = [
  {
    fieldName: "First Name",
    isRequired: true,
    validationType: "name",
    fieldType: "",
  },
  {
    fieldName: "Last Name",
    isRequired: true,
    validationType: "name",
    fieldType: "",
  },
  {
    fieldName: "Email",
    isRequired: true,
    validationType: "email",
    fieldType: "",
  },
];

const ConsentForm = ({ chatId, formData, setFormData, showForm, disabled }) => {
  const [inputData, setInputData] = useState({});
  const [errors, setErrors] = useState({});
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [isLoading, setIsLoading] = useState(true);
  const hasFetchedData = useRef(false);

  const consent = "true";

  useEffect(() => {
    const fetchFormFields = async () => {
      if (!isLoading) return;

      if (!chatId) {
        setIsLoading(false);
        return;
      }
      if (hasFetchedData.current) return;
      hasFetchedData.current = true;

      setIsLoading(true);
      try {
        const response = await getBotForm(chatId);
        setFormFields(response.formFields || defaultFormFields);
      } catch (error) {
        console.error("Failed to fetch bot form fields:", error);
        setFormFields(defaultFormFields);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFormFields();
  }, [chatId, isLoading]);

  const validationPatterns = {
    email:
      /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    phone: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
    alphanumeric: /^[a-zA-Z0-9]*$/,
    letters: /^[a-zA-Z]*$/,
    postalCode: /^[0-9]{5}$/,
    url: /^(https?:\/\/)?([\w\d-]+\.)+\/?/,
    number: /^[0-9]+$/,
    none: /.*/,
  };

  const sanitizeData = (data) => {
    const sanitizedData = {};
    Object.keys(data).forEach((key) => {
      let value = data[key];
      if (typeof value === "string") {
        sanitizedData[key] = value.trim().replace(/[<>\\/\\]/g, "");
      } else {
        sanitizedData[key] = value;
      }
    });
    return sanitizedData;
  };

  const validate = () => {
    const newErrors = {};
    formFields.forEach((field) => {
      const value = inputData[field.fieldName] || "";
      const pattern = validationPatterns[field.validationType] || /.*/;

      if (field.isRequired && !value) {
        newErrors[field.fieldName] = `${field.fieldName} is required`;
      } else if (value && !pattern.test(value)) {
        newErrors[field.fieldName] = `Invalid ${field.fieldName.toLowerCase()}`;
      }
    });
    return newErrors;
  };

  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      const formattedNumber = [
        match[1],
        match[2] && `-${match[2]}`,
        match[3] && `-${match[3]}`,
      ]
        .filter(Boolean)
        .join("");
      return formattedNumber;
    }
    return value;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    let formattedValue = value;

    if (id === "Phone") {
      formattedValue = formatPhoneNumber(value);
      if (formattedValue.length > 12) return;
    }

    setInputData((prevState) => ({
      ...prevState,
      [id]: formattedValue,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      const sanitizedFormData = sanitizeData(inputData);
      const formDataToSubmit = {
        ...sanitizedFormData,
        consent,
        isFormSubmitted: true,
      };

      // Avoid triggering setState that can lead to an infinite loop
      if (JSON.stringify(formData) !== JSON.stringify(formDataToSubmit)) {
        setFormData(formDataToSubmit);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const hideForm = disabled ? showForm : !formData.isFormSubmitted;

  return (
    <div className={`consent-form ${hideForm && "consent-form--show"}`}>
      {isLoading ? (
        <div className="loader">Loading form...</div>
      ) : (
        <form className="consent-form__container" onSubmit={handleSubmit}>
          {formFields.map((field, index) => (
            <div key={index} className="consent-form__group">
              <label className="consent-form__label" htmlFor={field.fieldName}>
                {field.fieldName}
              </label>
              <input
                className="consent-form__input"
                id={field.fieldName}
                type={field.validationType === "email" ? "email" : "text"}
                value={inputData[field.fieldName] || ""}
                onChange={handleChange}
                disabled={disabled}
              />
              {errors[field.fieldName] && (
                <span className="consent-form__error">
                  {errors[field.fieldName]}
                </span>
              )}
            </div>
          ))}

          <div className="consent-form__group">
            <span className="consent-form__label disclaimer" htmlFor="consent">
              This chat uses AI to provide assistance. Please check our
              disclaimer page for additional information and our data usage
              policies.
            </span>
          </div>

          <button
            className="consent-form__submit"
            type="submit"
            disabled={disabled}
          >
            Start Chat
          </button>
        </form>
      )}
    </div>
  );
};

export default ConsentForm;
