import { getBotAppearance } from "./grabBotData";

const sanitizeCssColor = (value) => {
  const s = new Option().style;
  s.color = "";
  s.color = value.trim();
  if (s.color !== "") {
    return s.color;
  } else {
    return null;
  }
};

const sanitizeChatTheme = (value) => {
  const theme = value.trim().toLowerCase();
  if (theme === "dark" || theme === "light") {
    return theme;
  } else {
    return null;
  }
};

export const buildCss = async () => {
  const sanitizeInput = (value) => value.replace(/[<>\\/\\]/g, "");
  const params = new URLSearchParams(window.location.search);
  const pathId = sanitizeInput(window.location.pathname || "");
  const paramId = sanitizeInput(params?.get("id") || "");
  const PID = pathId || paramId || "";

  const cssVariableMapping = {
    chatBoxBackgroundColor: "--chatBoxBackground",
    chatBoxUserMessageTextColor: "--messageUserText",
    chatBoxUserMessageBackgroundColor: "--messageUserBg",
    chatBoxAIMessageBackgroundColor: "--messageBotBg",
    chatBoxAIMessageTextColor: "--messageBotText",
    chatBoxAIMessageIconColor: "--botIcon",
    chatBoxAIMessageIconBgColor: "--botIconBg",
    chatBoxButtonText: "--buttonText",
    chatBoxButtonBg: "--buttonBg",
    chatBoxError: "--error",
    chatBoxInputText: "--inputText",
    chatBoxInputBg: "--inputBg",
    chatBoxChatTitle: "--chatTitle",
    chatBoxMessageLinks: "--messageLinks",
  };

  let cssVariables = ":root {\n";
  let hasVariables = false;
  let botAppearance = [];
  if (PID) {
    try {
      botAppearance = await getBotAppearance(PID);
      if (botAppearance) {
        const chatTheme = botAppearance.chatTheme;
        if (chatTheme === "dark") {
          document.body.classList.add("dark");
        }
        for (const [apiField, cssVar] of Object.entries(cssVariableMapping)) {
          const value = botAppearance[apiField];
          if (value) {
            cssVariables += `  ${cssVar}: ${value} !important;\n`;
            hasVariables = true;
          }
        }
      }
    } catch (error) {
      // TODO: handle error
    }
  } else {
    const chatThemeParam = params.get("chatTheme");
    if (chatThemeParam) {
      const sanitizedChatTheme = sanitizeChatTheme(chatThemeParam);
      if (sanitizedChatTheme === "dark") {
        document.body.classList.add("dark");
      }
    }

    for (const [paramName, value] of params.entries()) {
      if (paramName in cssVariableMapping) {
        const sanitizedValue = sanitizeCssColor(value);
        if (sanitizedValue !== null) {
          const cssVar = cssVariableMapping[paramName];
          cssVariables += `  ${cssVar}: ${sanitizedValue} !important;\n`;
          hasVariables = true;
        }
      }
    }
  }

  cssVariables += "}\n";

  if (hasVariables) {
    const styleElement = document.createElement("style");
    styleElement.type = "text/css";
    styleElement.appendChild(document.createTextNode(cssVariables));
    document.head.appendChild(styleElement);
  }
  const title = botAppearance?.chatNickname || "Neevai iframe chat";
  document.title = title;
};
