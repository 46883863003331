const apiUrl = process.env.REACT_APP_API_URL_STAGING;

export const getBotForm = async (id) => {
  const url = `${apiUrl}/ChatBotManagement/bot_form/SID/${id}`;
  const response = await fetch(url, {
    method: "GET",
  });
  return await response.json();
};

export const getBotAppearance = async (id) => {
  const url = `${apiUrl}/ChatBotManagement/bot_appearance/SID/${id}`;
  const response = await fetch(url, {
    method: "GET",
  });
  return await response.json();
};
