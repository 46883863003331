import React from "react";
import { createRoot } from "react-dom/client";
import Chat from "./components/Chat";
import "./index.scss";
import { buildCss } from "./api/buildCss";

const root = createRoot(document.getElementById("root"));

buildCss();

root.render(
  <React.StrictMode>
    <div id="shuts-widget-chat">
      <Chat />
    </div>
  </React.StrictMode>
);
